import { Router, Storage } from '@lightningjs/sdk';
// @ts-expect-error TS(7016): Could not find a declaration file for module '@lig... Remove this comment to see the full error message
import { app } from '@lightningjs/sdk/src/Router/utils/router';
import { CONTENT_TYPE, DESTINATION_TYPES, PROGRAMMING_TYPES, REGISTRATION_REFERRERS, ROUTE, ROUTE_ENTITY, STORAGE_KEYS, } from '../constants';
import { LiveStreamManager } from '../lib/LiveStreamManager';
class RouterUtil {
    static current() {
        return Router.getActiveRoute();
    }
    static isShowHomePage() {
        return RouterUtil.current() === ROUTE.showHome;
    }
    static isHomePage() {
        return RouterUtil.current() === ROUTE.home;
    }
    static isShowPage() {
        return RouterUtil.current() === ROUTE.shows;
    }
    static getPrevRoute() {
        var _a;
        const history = Router.getHistory();
        if ((history === null || history === void 0 ? void 0 : history.length) === 0)
            return false;
        return (_a = history[history.length - 1]) === null || _a === void 0 ? void 0 : _a.hash;
    }
    static getInternalPageParams(item) {
        var _a;
        return item.isLive || item.destination === ROUTE.live ? { assetMetadata: (_a = item === null || item === void 0 ? void 0 : item.obj) === null || _a === void 0 ? void 0 : _a.data } : {};
    }
    static isComingFromHomePage() {
        return this.getPrevRoute() === ROUTE.home;
    }
    static checkGetRegistrationReferrer() {
        const prev = Router.getActiveRoute();
        if (!prev)
            return 'None';
        switch (prev) {
            case ROUTE.profile:
                return REGISTRATION_REFERRERS.MVPD;
            // @ts-expect-error TS(2678): Type 'boolean' is not comparable to type 'string'.
            case prev.includes('activation'):
                return REGISTRATION_REFERRERS.VOD;
            case ROUTE.live:
                return REGISTRATION_REFERRERS.LIVE;
            // case 'favorites cta' What is this? Todo
            case ROUTE.credits:
                return REGISTRATION_REFERRERS.VOD;
            default:
                return 'None';
        }
    }
    static showsBrandRoutingData(brand, prevBrand, isCat = false, brandForCat = null) {
        const data = {
            path: ROUTE.shows,
            brand: isCat ? brandForCat : brand,
            category: isCat ? brand : null,
            pageName: {
                current: {
                    name: `All Shows : ${brand}`,
                    type: 'Shows',
                },
            },
        };
        if (prevBrand) {
            // @ts-expect-error TS(2339): Property 'previous' does not exist on type '{ curr... Remove this comment to see the full error message
            data.pageName.previous = {
                name: `All Shows : ${prevBrand}`,
                type: 'Shows',
            };
        }
        return data;
    }
    static navigateByDestinationType(item) {
        if (!item)
            return;
        switch (item.destinationType) {
            case DESTINATION_TYPES.video:
                RouterUtil.navigateToRoute(ROUTE.video, {
                    entity: ROUTE_ENTITY.videoId,
                    value: item.destination,
                }, { isOlympicsVideo: item === null || item === void 0 ? void 0 : item.isOlympics });
                break;
            case DESTINATION_TYPES.series:
                RouterUtil.navigateToRoute(ROUTE.showHome, {
                    entity: ROUTE_ENTITY.urlAlias,
                    value: item.urlAlias,
                });
                break;
            case DESTINATION_TYPES.internalPage: {
                if (item.isLive) {
                    Router.navigate(ROUTE.live, RouterUtil.getInternalPageParams(item)); // Navigate to live route if item is live
                }
                else if (RouterUtil.matchRoute(item.destination)) {
                    // Check if the destination matches the available routes
                    Router.navigate(item.destination, RouterUtil.getInternalPageParams(item));
                }
                else {
                    // Default to the show page
                    RouterUtil.navigateToRoute(ROUTE.showHome, {
                        entity: ROUTE_ENTITY.urlAlias,
                        value: item.destination,
                    });
                }
                break;
            }
            case DESTINATION_TYPES.playlist: {
                Storage.set(STORAGE_KEYS.PLAYLIST_MACHINE_NAME, item.playlistMachineName);
                RouterUtil.navigateToRoute(ROUTE.video, {
                    entity: ROUTE_ENTITY.videoId,
                    value: item.destination,
                });
                break;
            }
            case DESTINATION_TYPES.singleEvent: {
                if (item.contentType === CONTENT_TYPE.FER || item.contentType === PROGRAMMING_TYPES.FER)
                    RouterUtil.navigateToRoute(ROUTE.watch, {
                        entity: ROUTE_ENTITY.pid,
                        value: item.pid || item.destination,
                    });
                break;
            }
            default:
                break;
        }
    }
    static navigateToRoute(route, { entity, value }, params = {}) {
        if (!entity || !value)
            return;
        const currentHash = Router.getActiveHash();
        const newHash = route.replace(entity, value);
        Router.navigate(newHash, { ...params, reload: newHash === currentHash });
    }
    /**
     * Сheck if the first part of the string contains a name similar to the URL used by the player.
     * @param path
     * @returns {boolean}
     */
    static isPlayerRoute(path) {
        return /^(video|movie|show|fer|sle)\//.test(path);
    }
    static removePreviousRoute() {
        const previousHistory = Router.getHistory();
        previousHistory.pop();
        Router.setHistory(previousHistory);
    }
    static matchRoute(path) {
        const routes = Object.values(ROUTE)
            .filter((route) => route !== ROUTE.notFound)
            .map((route) => {
            const pattern = route //eslint-disable-next-line
                .replace(/:([^/\?]+)\?/g, '(?:/([^/]+))?') //eslint-disable-next-line
                .replace(/:([^/\?]+)/g, '/([^/]+)')
                .replace(/\//g, '\\/');
            return { route, regex: new RegExp(`^${pattern}$`) };
        });
        for (const { route, regex } of routes) {
            if (regex.test(path)) {
                return route;
            }
        }
        return null;
    }
}
RouterUtil.setAppState = (state = '') => {
    typeof (app === null || app === void 0 ? void 0 : app._setState) === 'function' && app._setState(state);
};
RouterUtil.navigateToLive = (fireAncestors, { channelId, streamAccessName, callSign = '', }, preventClickMetric = false) => {
    Storage.remove(STORAGE_KEYS.PREVIOUS_STREAM);
    LiveStreamManager.set(channelId, streamAccessName, callSign);
    if (Router.getActiveRoute() === ROUTE.live)
        fireAncestors('$showSLE');
    else
        Router.navigate(ROUTE.live, { preventClickMetric });
};
export default RouterUtil;
