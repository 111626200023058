import BasePlatform, { PlatformSubscriptionType, SubscriptionWrapper, TV_PLATFORM_TAG, } from '../base';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from '../types';
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import { SupportedPlatforms } from '../../../graphql/generated/types';
import { KeyCodes, Keys, USER_OPT_OUT_PREFERENCE } from '../../../constants';
import KeplerAnnounce from '../../tts/Announces/KeplerAnnounce';
import BaseAnnounce from '../../tts/Announces/BaseAnnounce';
import { Registry } from '@lightningjs/sdk';
import { INCOMING_KEPLER_MESSAGE_TYPE, OUTGOING_KEPLER_MESSAGE_TYPE, } from './types';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import RouterUtil from '../../../util/RouterUtil';
import { setAppLaunchType } from '../../../helpers';
import { AppLaunchTypes } from '../../analytics/types';
import Router from '@lightningjs/sdk/src/Router';
class KeplerSubscriptionWrapper extends SubscriptionWrapper {
    constructor(unsubscribeCb) {
        super();
        this.stale = false;
        this._unsubscribeCb = unsubscribeCb;
    }
    unsubscribe() {
        var _a;
        this.stale = true;
        (_a = this._unsubscribeCb) === null || _a === void 0 ? void 0 : _a.call(this);
    }
}
export default class KeplerPlatform extends BasePlatform {
    constructor() {
        super(...arguments);
        this._platformName = 'Kepler';
        this._lemonadePlatform = LEMONADE_PLATFORM.KEPLER;
        this._bffPlatform = SupportedPlatforms.FireTvLightning;
        this._streamingProtocol = StreamingProtocol.DASH;
        this._appIdentifier = APP_IDENTIFIER.KEPLER;
        this.subscribe = (evt, callback) => {
            // Filter stale events
            this._subscriptions = this._subscriptions.filter(({ stale }) => !stale);
            if (evt === PlatformSubscriptionType.VOICE) {
                if (this._ttsEnabled()) {
                    callback === null || callback === void 0 ? void 0 : callback(this._ttsEnabled());
                    const handleMessage = ({ isTTSEnabled }) => callback(isTTSEnabled);
                    Registry.addEventListener(window, 'ttsHasChanged', handleMessage);
                }
            }
            return undefined;
        };
        this._sendMessageToShell = (messageType) => {
            if (window === null || window === void 0 ? void 0 : window.ReactNativeWebView) {
                const message = { type: messageType };
                window.ReactNativeWebView.postMessage(JSON.stringify(message));
            }
        };
        /**
         * handle redirecting to the deep link if the initial
         * deep linking event has been captured on app launch
         */
        this.checkDeepLinkNavigation = () => {
            if (this._keplerConfig) {
                const { contentLauncherData } = this._keplerConfig;
                const { entityID = '' } = contentLauncherData;
                if (entityID && entityID.includes('://')) {
                    // remove brand prefix ie: nbctve:// or telemundotve://
                    // and remove leading forward slash
                    const routePath = entityID.replace(/(.*?):\/\//g, '');
                    Router.navigate(routePath, !RouterUtil.isPlayerRoute(routePath));
                    setAppLaunchType(AppLaunchTypes.deepLink);
                    //reseting deeplink value
                    this._keplerConfig.contentLauncherData = { entityID: '' };
                }
            }
        };
    }
    get devicePartnerId() {
        var _a, _b;
        return ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.identity) === null || _b === void 0 ? void 0 : _b.device_type) || 'FireTv';
    }
    async init() {
        this._deviceType = 'Fire TV';
        await this.generateDeviceId();
        //listen for the initial config from kepler
        Registry.addEventListener(window, 'message', ({ data }) => {
            var _a;
            console.log('>>> message', data);
            switch (data.type) {
                case INCOMING_KEPLER_MESSAGE_TYPE.SET_CONFIG:
                    this._keplerConfig = data.payload;
                    break;
                case INCOMING_KEPLER_MESSAGE_TYPE.DEVICE_INFO:
                    this._model = (_a = data.payload) === null || _a === void 0 ? void 0 : _a.deviceModel;
                    break;
                // case INCOMING_KEPLER_MESSAGE_TYPE.CONTENT_LAUNCHER: {
                //   console.log('content launch', data.payload)
                //   const { entityID = '' } = data.payload
                //   const route = entityID.replace(/nbctve:\/\//g, '')
                //   TVPlatformSingleton.get().setDeepLink(route)
                //   break
                // }
                default:
                    break;
            }
        });
        this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.LOADED);
        this._sendMessageToShell(INCOMING_KEPLER_MESSAGE_TYPE.DEVICE_INFO);
    }
    /**
     * exit the application
     * @returns {void}
     */
    exit() {
        try {
            this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.EXIT);
            super.exit();
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error on Kepler exit',
                payload: e,
            });
        }
    }
    /**
     * firetv specific key bindings
     * @returns {boolean}
     */
    getPlatformKeyMapping() {
        return {
            ...this.getAtoZAndNumberKeyMapping(true),
            ...super.getPlatformKeyMapping(),
            [KeyCodes.kepler.Back]: Keys.BACKSPACE,
            [KeyCodes.kepler.PlayPause]: Keys.MEDIA_PLAY_PAUSE,
            [KeyCodes.kepler.Rewind]: Keys.MEDIA_REWIND,
            [KeyCodes.kepler.FastForward]: Keys.MEDIA_FAST_FORWARD,
        };
    }
    tts() {
        return {
            speak(toSpeak, notification = false) {
                return window.ReactNativeWebView
                    ? new KeplerAnnounce(toSpeak, notification)
                    : new BaseAnnounce(toSpeak, notification);
            },
            cancel() {
                // todo: cancel method for kepler
            },
        };
    }
    _ttsEnabled() {
        var _a;
        //todo: enact tts enabled when available in webview
        return ((_a = this._keplerConfig) === null || _a === void 0 ? void 0 : _a.ttsEnabled) || false;
    }
    /**
     * Advertiser Id is set on the initial config passed from device
     * @returns {string}
     * @private
     */
    getAdvertiserId() {
        var _a;
        return ((_a = this._keplerConfig) === null || _a === void 0 ? void 0 : _a.adTrackingEnabled)
            ? this._keplerConfig.advertiserId
            : super.getAdvertiserId();
    }
    async getModelNumber() {
        if (this._model) {
            return this._model;
        }
        return await super.getModelNumber();
    }
    /**
     * User opt out value for ads  1 = false (disallow), 0 = true (allow)
     * Evaluate the storage (set through privacy settings)
     * The debug override (set in debug menu)
     * Additionally, if user does not have an advertiser id, we should disallow
     * advertiserId will come back as a blank string if not enabled from XBox
     *
     * @returns {boolean}
     */
    getUserOptOut() {
        var _a;
        try {
            const storageOptOut = this.getStorageBasedOptOut();
            return !((_a = this._keplerConfig) === null || _a === void 0 ? void 0 : _a.adTrackingEnabled) ||
                storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        }
        catch (e) {
            return super.getUserOptOut();
        }
    }
}
