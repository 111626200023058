var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
import { Log } from '@lightningjs/sdk';
import mParticleInterface from './mParticle/mParticleInterface';
import { EVENTS } from './types';
class Analytics {
    constructor() {
        this._currentVideo = null;
        this._isBuffering = false;
        this._isLive = false;
        this._isSeeking = false;
        this._mParticle = new mParticleInterface();
        this.initialize = async () => {
            const successCallback = (payload) => {
                Log.info('mParticle Initialization Success ', payload);
            };
            await this._mParticle.initialize(successCallback);
        };
        this.onAppClosed = () => { };
        this.cleanUpSession = () => {
            this._currentVideo = null;
            if (this._isBuffering)
                this._isBuffering = false;
            if (this._isSeeking)
                this._isSeeking = false;
        };
        // #region Events
        // General
        this[_a] = (params) => {
            this._mParticle.trackError(params);
        };
        this[_b] = (params) => {
            this._mParticle.trackExit(params);
        };
        // Ads
        this[_c] = (adBreak) => {
            this._mParticle.trackAdBreakStart(this._currentVideo, adBreak, this._isLive);
        };
        this[_d] = (adBreak) => {
            this._mParticle.trackAdBreakEnd(this._currentVideo, adBreak, this._isLive);
        };
        this[_e] = (ad) => {
            this._mParticle.trackAdStart(this._currentVideo, ad, this._isLive);
        };
        this[_f] = (ad) => {
            this._mParticle.trackAdEnd(this._currentVideo, ad, this._isLive);
        };
        // Seeking
        this[_g] = () => {
            if (this._isSeeking)
                return;
            this._isSeeking = true;
        };
        this[_h] = () => {
            if (!this._isSeeking)
                return;
            this._isSeeking = false;
        };
        // Live
        this[_j] = () => {
            this._mParticle.trackLivePlayerLoad();
        };
        this[_k] = (metadata) => {
            this._mParticle.trackLiveProgramChange(metadata, this._currentVideo);
            this._currentVideo = metadata;
        };
        this[_l] = (metadata) => {
            this._isLive = true;
            this._currentVideo = metadata;
            this._mParticle.trackLiveSessionStart(metadata);
        };
        this[_m] = (metadata) => {
            if (this._currentVideo) {
                this._mParticle.trackLiveSessionEnd({
                    ...metadata,
                    _currentVideo: this._currentVideo,
                });
                this.cleanUpSession();
            }
        };
        // VOD
        this[_o] = (metadata) => {
            this._isLive = false;
            this._currentVideo = metadata;
            this._mParticle.trackVodSessionStart(metadata);
        };
        this[_p] = (metadata) => {
            this._mParticle.trackVodSessionEnd(metadata);
            this.cleanUpSession();
        };
        // Player
        this[_q] = () => {
            this._isBuffering = true;
        };
        this[_r] = () => {
            if (!this._isBuffering)
                return;
            this._isBuffering = false;
        };
        this[_s] = () => {
            this._mParticle.trackPlayerLoadTime(this._currentVideo);
        };
        // Auth
        this[_t] = () => {
            this._mParticle.trackAuthZStart();
        };
        this[_u] = () => {
            this._mParticle.trackAuthZComplete();
        };
        this[_v] = (params) => {
            this._mParticle.trackMvpdAuthSuccess(params);
        };
        this[_w] = (params) => {
            this._mParticle.trackNbcAuthSuccess(params);
        };
        this[_x] = (metadata) => {
            this._mParticle.trackIDMConversion(metadata);
        };
        this[_y] = (params) => {
            this._mParticle.trackMvpdPageAbandoned(params);
        };
        this[_z] = () => {
            this._mParticle.trackNbcProfileSignOut();
        };
        // User actions
        this[_0] = (routeData) => {
            this._mParticle.trackPageLoad(routeData);
        };
        this[_1] = (params) => {
            this._mParticle.trackClick(params);
        };
        this[_2] = (params) => {
            this._mParticle.trackContentClick(params);
        };
        this[_3] = (metadata) => {
            this._mParticle.trackSearchResult(metadata);
        };
        this[_4] = (params) => {
            this._mParticle.trackShelfImpression(params);
        };
        this[_5] = (params) => {
            this._mParticle.trackMarketingModuleImpression(params);
        };
        this[_6] = (params) => {
            this._mParticle.trackMarketingModuleVideoPreview(params);
        };
        this[_7] = (params) => {
            this._mParticle.trackShelvesLoad(params);
        };
        this[_8] = (params) => {
            this._mParticle.trackModalLoad(params);
        };
        this[_9] = (params) => {
            this._mParticle.trackDynamicLeadImpression(params);
        };
        this[_10] = (params) => {
            this._mParticle.trackEndCardImpression(params);
        };
        this[_11] = (params) => {
            this._mParticle.trackEndCard(params);
        };
        this[_12] = (params) => {
            this._mParticle.trackLiveToVodImpression(params);
        };
        // #endregion
    }
    setUserAttribute(key, value) {
        this._mParticle.setUserAttribute(key, value);
    }
    updateUserDefaultAttributes(tempPassTokenName) {
        this._mParticle.updateDefaultUserAttributes(tempPassTokenName);
    }
}
_a = EVENTS.ERROR, _b = EVENTS.EXIT, _c = EVENTS.AD_BREAK_START, _d = EVENTS.AD_BREAK_END, _e = EVENTS.AD_START, _f = EVENTS.AD_END, _g = EVENTS.SEEK_START, _h = EVENTS.SEEK_END, _j = EVENTS.LIVE_SESSION_INITIALIZED, _k = EVENTS.LIVE_PROGRAM_CHANGE, _l = EVENTS.LIVE_SESSION_START, _m = EVENTS.LIVE_SESSION_END, _o = EVENTS.VOD_SESSION_START, _p = EVENTS.VOD_SESSION_END, _q = EVENTS.ON_BUFFER_START, _r = EVENTS.ON_BUFFER_COMPLETE, _s = EVENTS.PLAYER_READY, _t = EVENTS.AUTHZ_START, _u = EVENTS.AUTHZ_COMPLETE, _v = EVENTS.AUTH_SUCCESS_MVPD, _w = EVENTS.AUTH_SUCCESS_NBC, _x = EVENTS.IDM_CONVERSION, _y = EVENTS.MVPD_PAGE_ABANDONED, _z = EVENTS.NBC_PROFILE_SIGN_OUT, _0 = EVENTS.PAGE_LOAD, _1 = EVENTS.CLICK, _2 = EVENTS.CONTENT_CLICK, _3 = EVENTS.SEARCH_RESULT, _4 = EVENTS.SHELF_IMPRESSION, _5 = EVENTS.MARKETING_MODULE_IMPRESSION, _6 = EVENTS.MARKETING_MODULE_VIDEO_PREVIEW, _7 = EVENTS.SHELVES_LOAD, _8 = EVENTS.MODAL_LOAD, _9 = EVENTS.DYNAMIC_LEAD_IMPRESSION, _10 = EVENTS.END_CARD_IMPRESSION, _11 = EVENTS.END_CARD, _12 = EVENTS.LIVE_TO_VOD_IMPRESSION;
const AnalyticsSingleton = new Analytics();
export function sendMetric(key, ...params) {
    // @ts-expect-error We are spreading a tuple
    AnalyticsSingleton[key](...params);
}
export default AnalyticsSingleton;
