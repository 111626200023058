import MParticleInterface from '../mParticleInterface';
import getAuthAttributes from '../attributes/getAuthAttributes';
import mParticle from '@mparticle/web-sdk';
import { getAssetAuthAnalytics, setDefaultValue } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
export const preTrackEvent = () => (properties) => MParticleInterface.setAuthAttributes(properties);
const getEvent = () => ({
    getAttributes: (params) => setDefaultValue({
        ...getAuthAttributes(params),
        ...getAssetAuthAnalytics(params.program),
    }, MPARTICLE_DEFAULT_ATTR_VALUE),
    getCategory: () => mParticle.EventType.UserPreference,
    type: 'MVPD Page Abandoned',
    injectGlobals: true,
    preTrackEvent: preTrackEvent(),
});
export default getEvent;
