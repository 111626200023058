import { Language, Router } from '@lightningjs/sdk';
import FocusScaledButton from './buttons/FocusScaledButton';
import BaseCollection from './BaseCollection';
import ItemWrapper from './items/ItemWrapper';
import ModalManager, { ModalTypes } from '../lib/ModalManager';
import Styler from '../lib/Styler';
import { sendMetric } from '../lib/analytics/Analytics';
import { EVENTS } from '../lib/analytics/types';
import { setSmooth, FastImg } from '../helpers';
import ContentPosition, { ScrollElementType } from '../util/contentPosition';
import { COLORS, END_OF_ROW, FLEX_DIRECTION, FONT_FACE, FULL_SCHEDULE } from '../constants';
import { OLY_IMPOLICY } from '../constants/images';
import Announcer from '../lib/tts/Announcer';
export default class List extends BaseCollection {
    constructor() {
        super(...arguments);
        this._index = 0;
    }
    static _template() {
        return {
            TitleHolder: {
                Title: {
                    alpha: 0.87,
                    text: { fontFace: FONT_FACE.light, fontSize: 40 },
                },
            },
            Items: {
                y: 85,
                forceZIndexContext: true,
                boundsMargin: [500, 100, 500, 100],
            },
        };
    }
    set initialItem(initialItem) {
        if (initialItem) {
            this.setIndex(initialItem);
        }
    }
    set initialItemHandle(initialItemHandle) {
        if (!initialItemHandle)
            return;
        const index = this._items.findIndex((item) => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.item) === null || _a === void 0 ? void 0 : _a.v4ID) === initialItemHandle; });
        if (index > -1) {
            this.initialItem = index;
        }
    }
    _build() {
        this._itemsHolder = this.tag('Items');
        this._titleHolder = this.tag('TitleHolder');
    }
    create({ items }, initialIndex = 0) {
        const style = this.getStyle();
        if (!style) {
            console.warn(`List.js ${items} missing style. Check ./src/lib/style.js`);
            return;
        }
        this.noOfItemsToScroll = style.noOfListItemsToScroll;
        return items.map((item, index) => {
            const indexToRender = index + initialIndex;
            return {
                type: ItemWrapper,
                itemType: item.itemType,
                item: item.item,
                index: indexToRender,
                x: indexToRender * style.item.w,
            };
        });
    }
    _handleLeft() {
        if (this._index > 0) {
            this.setIndex(this._index - 1);
            ContentPosition.UpdatePositionContentForCurrentPage(this._index);
        }
        else {
            return false;
        }
    }
    _handleRight() {
        if (this._index < this._itemsHolder.children.length - 1) {
            this.setIndex(this._index + 1);
            ContentPosition.UpdatePositionContentForCurrentPage(this._index);
        }
        else {
            Announcer.announce(END_OF_ROW);
            return false;
        }
    }
    _handleBack(e) {
        if (this._index === 0) {
            return false;
        }
        else {
            this.setIndex(0);
            ContentPosition.UpdatePositionContentForCurrentPage(this._index);
            e.preventDefault();
            e.stopPropagation();
        }
    }
    setIndex(index) {
        this._index = index;
        const style = this.getStyle();
        const noOfItems = this.noOfItemsToScroll || 4;
        setSmooth(this._itemsHolder, 'x', index > noOfItems - 1 ? (index - (noOfItems - 1)) * -style.item.w : 0, {
            duration: 0.3,
            delay: 0,
        });
        this.fireAncestors('$scrolledListItemIndex', { index });
        this._refocus();
    }
    _createViewAllCta() {
        var _a;
        const tag = this.getTag(this);
        const style = Styler.getTag(tag);
        const isFullScheduleText = FULL_SCHEDULE.indexOf((_a = this._viewAllCta.title) === null || _a === void 0 ? void 0 : _a.toLowerCase()) > -1;
        const label = isFullScheduleText
            ? Language.translate('full-schedule-view-all-cta') || this._viewAllCta.title
            : this._viewAllCta.title;
        return {
            x: this._items.length * style.item.w + 30,
            y: 98,
            unfocusHeight: 58,
            unfocusWidth: isFullScheduleText ? 260 : 134,
            focusHeight: 68,
            focusWidth: isFullScheduleText ? 280 : 158,
            type: FocusScaledButton,
            radius: 6,
            fontSize: 30,
            focusFontSize: 35,
            fontFace: FONT_FACE.regular,
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.lightGray4,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.transparent,
            focusBorderColor: COLORS.transparent,
            unfocusBorderColor: COLORS.lightGray4,
            padding: 22,
            label,
            enterCallback: () => {
                const { title, listTitle, destination, qrModal } = this._viewAllCta;
                sendMetric(EVENTS.CLICK, {
                    name: title,
                    shelf: {
                        listTitle,
                    },
                });
                this.trackCTAPosition();
                (qrModal === null || qrModal === void 0 ? void 0 : qrModal.data)
                    ? ModalManager.open(ModalTypes.QR_MODAL, qrModal)
                    : Router.navigate(destination.replace(/\//, ''), {
                        preventClickMetric: true,
                        loadAnnounce: this.fireAncestors('$getLoadAnnounce'),
                    });
            },
        };
    }
    trackCTAPosition() {
        var _a;
        const position = this.fireAncestors('$getCurrentScrollPosition');
        if (position) {
            position.content = ((_a = this === null || this === void 0 ? void 0 : this.items) === null || _a === void 0 ? void 0 : _a.length) || 0;
            ContentPosition.setPositionForCurrentPage(position);
        }
    }
    _getFocused() {
        return this.activeItemWrapper || this;
    }
    get activeItemWrapper() {
        return this._itemsHolder.children[this._index];
    }
    get noOfItems() {
        return this.items.length;
    }
    get index() {
        return this._index;
    }
    set itemType(v) {
        this._itemType = v;
    }
    get items() {
        return this._items;
    }
    set items(v) {
        this._itemsHolder.childList.clear();
        this.stage.gc();
        this._items = v;
        const itemsToRender = this.create({ items: this._items });
        if (this._viewAllCta)
            itemsToRender.push(this._createViewAllCta());
        this._itemsHolder.patch({
            children: itemsToRender,
        });
    }
    set viewAllCta(viewAllCta) {
        this._viewAllCta = viewAllCta;
    }
    get viewAllCta() {
        return this._viewAllCta;
    }
    _removeItem(itemToRemove) {
        this._itemsHolder.childList.remove(itemToRemove);
        if (this._itemsHolder.childList.length === 0) {
            this.fireAncestors('$onEmptyWrapperList', this);
        }
        this._repositionItems();
        this.setIndex(0);
    }
    _repositionItems() {
        const style = this.getStyle();
        this._itemsHolder.childList.forEach((item, index) => {
            item.patch({
                x: index * style.item.w,
            });
        });
    }
    set title(v) {
        if (v) {
            this.tag('Title').text = v;
        }
        else {
            this._itemsHolder.patch({
                y: 0,
            });
        }
    }
    get title() {
        var _a, _b;
        return (_b = (_a = this.tag('Title')) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.text;
    }
    set sponsorLogo(v) {
        this.patch({
            TitleHolder: {
                flex: { direction: FLEX_DIRECTION.row },
                Title: {
                    flexItem: { marginRight: 15 },
                },
                SponsorLogo: {
                    mountY: 1,
                    texture: FastImg(v).contain(296, 82, OLY_IMPOLICY.RECT_SMALL_384_216),
                },
            },
        });
        this.tag('Title').on('txLoaded', () => {
            this.tag('SponsorLogo').y = this.tag('Title').renderHeight - 17;
        });
    }
    set noOfItemsToScroll(noOfItems) {
        this._noOfItemsToScroll = noOfItems;
    }
    get noOfItemsToScroll() {
        return this._noOfItemsToScroll || 4;
    }
    $getCurrentScrollPosition() {
        const position = this.fireAncestors('$getCurrentScrollPosition');
        return {
            ...position,
            type: ScrollElementType.LIST,
            row: (position === null || position === void 0 ? void 0 : position.row) || 0,
            instanceID: position === null || position === void 0 ? void 0 : position.instanceID,
        };
    }
}
