import { Language, Router, Storage, Utils } from '@lightningjs/sdk';
import { LongScroll } from '../../components';
import LongScrollPage from '../LongScrollPage';
import Hero from '../../components/items/Hero/Hero';
import SubMenu from '../../components/submenu';
import ShowAbout from '../../components/showabout';
import { ItemMenuStateFactory } from './states/ItemMenu';
import { FeaturedStateFactory } from './states/Featured';
import { ScrollerStateFactory } from './states/Scroller';
import { AboutStateFactory } from './states/About';
import { SectionsSpawner } from '../../api/spawners';
import { removeHtmlParams, saveLatestCachedItem, setSmooth, splitString } from '../../helpers';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import { evict as evictFromCache } from '../../graphql/cache';
import { FavoriteInteractionFactorySingleton } from '../../graphql/modifiers/FavoriteInteractionFactory';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import ContentPosition from '../../util/contentPosition';
import { ALIGN_SELF, COLORS, COMPONENT_TYPES, FLEX_DIRECTION, FONT_FACE, PARK_ENTITY_TYPES, ROUTE, SCREEN_SIZE, VERTICAL_ALIGN, } from '../../constants';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import UserInteractionsStoreSingleton from '../../store/UserInteractions';
import { addFavorite, removeFavorite } from '../../store/UserInteractions/actions';
import { useRequest } from '../../lib/useRequest';
import { ShowHomeFeaturedRequestConfig, ShowHomeRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
export const ContainerDelegateTypes = {
    videoTile: 'videoTileShowHome',
};
// @ts-expect-error TS(2417): Class static side 'typeof ShowHome' incorrectly ex... Remove this comment to see the full error message
class ShowHome extends LongScrollPage {
    constructor() {
        super(...arguments);
        this.updateFavoritesCache = () => {
            var _a;
            // Initialize Favorites class to update local cache.
            if (!this._favoriteInteractionCache)
                this._favoriteInteractionCache = FavoriteInteractionFactorySingleton.get();
            this._isFavorite = !this._isFavorite;
            this.tag('SubNav').activeItem.item = this._isFavorite ? this._removeItem : this._favoriteItem;
            // Add/remove/update homepage favorites shelf.
            this._favoriteInteractionCache.addOrRemoveFavorite((_a = this.params) === null || _a === void 0 ? void 0 : _a.urlalias, this._isFavorite);
            // Invalidate show cache to re-fetch favorites status.
            const showId = Storage.get('bff.showCacheId');
            if (showId)
                evictFromCache(showId);
        };
    }
    static _template() {
        return {
            Featured: {
                type: Hero,
                customPosition: 1,
            },
            Scroller: {
                type: LongScroll,
                scrollFocus: 0.5,
                x: 80,
                y: 900,
                transitions: {
                    y: {
                        duration: 0.3,
                        timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
                    },
                },
            },
            SubNav: {
                type: SubMenu,
                mountX: 1,
                x: 1250,
                y: 843,
            },
            ShowAbout: {
                alpha: 0,
                type: ShowAbout,
                x: 0,
                y: 0,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
            },
            BackReturn: {
                alpha: 0,
                x: 1861,
                y: 1038,
                mount: 1,
                flex: { direction: FLEX_DIRECTION.row },
                Item1: {
                    flexItem: { alignSelf: ALIGN_SELF.center },
                    text: {
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        textColor: COLORS.mediumGray1,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        text: Language.translate('press'),
                    },
                },
                Item2: {
                    flexItem: {
                        marginLeft: 12,
                        marginRight: 12,
                        alignSelf: ALIGN_SELF.center,
                    },
                    src: Utils.asset('images/return.png'),
                },
                Item3: {
                    flexItem: { alignSelf: ALIGN_SELF.center },
                    text: {
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        textColor: COLORS.mediumGray1,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        text: Language.translate('return_to_top'),
                    },
                },
            },
        };
    }
    _init() {
        this._initialState = 'Featured';
        this._removePersonalization = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.removePersonalization);
        this._contentPosition = ContentPosition;
        this.stage.setClearColor(COLORS.dark);
        // Favorites class, initialized if needed.
        this._favoriteInteractionCache = null;
    }
    set apiData(v) {
        var _a, _b, _c;
        if (!v)
            Router.navigate(ROUTE.error);
        if (!(v === null || v === void 0 ? void 0 : v.analytics) || !(v === null || v === void 0 ? void 0 : v.metadata) || !(v === null || v === void 0 ? void 0 : v.sections))
            return;
        this.analytics = v.analytics;
        this.metadata = v.metadata;
        this.sections = v.sections.map((_section, index) => {
            var _a;
            const position = (_a = this._contentPosition) === null || _a === void 0 ? void 0 : _a.getPositionForCurrentPage();
            if ([COMPONENT_TYPES.LINK_SELECTABLE_GROUP, COMPONENT_TYPES.PLACEHOLDER]
                .includes(_section === null || _section === void 0 ? void 0 : _section.component)) {
                _section = { ..._section };
                if (position && position.row === index && typeof position.labelIndex === 'number') {
                    _section.data.initiallySelected = position.labelIndex;
                    if (position.v4ID) {
                        _section.data.initialItemHandle = position.v4ID;
                    }
                }
                // Force in a fontsize - 70 for season 40 for category (clips, etc.)
                // Use translation here as spanish won't match otherwise.
                _section.fontSize = _section.data.itemLabelsTitle === Language.translate('season') ? 70 : 40;
            }
            return _section;
        });
        // @ts-expect-error TS(2339): Property 'urlAlias' does not exist on type 'Stage'... Remove this comment to see the full error message
        this.stage.urlAlias = this.params.urlAlias;
        SectionsSpawner(this.stage, this.sections, ContainerDelegateTypes).then((containers) => {
            this.containers = containers;
        });
        this.favorite = v !== null && v !== void 0 ? v : {};
        this.brand = (_b = (_a = v === null || v === void 0 ? void 0 : v.analytics) === null || _a === void 0 ? void 0 : _a.brand) === null || _b === void 0 ? void 0 : _b.title;
        this.series = (_c = v === null || v === void 0 ? void 0 : v.analytics) === null || _c === void 0 ? void 0 : _c.series;
        // Fire Page Load now that we have show data.
        sendMetric(EVENTS.PAGE_LOAD, this._getMetricsData(v));
        saveLatestCachedItem('bff.showCacheId', v.id);
    }
    get urlAlias() {
        var _a, _b;
        return (((_a = this.params) === null || _a === void 0 ? void 0 : _a.urlAlias) || ((_b = this.params) === null || _b === void 0 ? void 0 : _b.itemName));
    }
    _getMetricsData(data) {
        var _a, _b, _c;
        const meta = {
            ...data.analytics,
            ...data.metadata,
            brand: (_a = data === null || data === void 0 ? void 0 : data.analytics) === null || _a === void 0 ? void 0 : _a.brand,
            path: ((_b = data === null || data === void 0 ? void 0 : data.analytics) === null || _b === void 0 ? void 0 : _b.movie) ? 'movie/:itemName' : 'show/:urlalias',
            show: this.series,
        };
        // If its a movie w/out shortTitle, getTitle from analtytics.
        if (meta.shortTitle === null && ((_c = data === null || data === void 0 ? void 0 : data.analytics) === null || _c === void 0 ? void 0 : _c.movie)) {
            meta.title = data.analytics.movie;
        }
        return meta;
    }
    async load() {
        try {
            this.apiData = await useRequest(ShowHomeRequestConfig(this.urlAlias)).fetch();
            this.loadFeatured();
            this._setState(this._initialState);
        }
        catch (e) {
            this._setState(WithRequestErrorState);
        }
    }
    loadFeatured() {
        useRequest(ShowHomeFeaturedRequestConfig(this.urlAlias))
            .fetch()
            .then((featured) => {
            this.featured = featured;
        })
            .catch(() => this._setState('Scroller'))
            .finally(() => setSmooth(this.widgets.loader, 'alpha', 0));
    }
    set featured(v) {
        var _a;
        if (!v)
            return;
        this._featured = {
            featured: (_a = v.data) !== null && _a !== void 0 ? _a : {},
        };
        this.about = v.data;
        this.patch({
            Featured: {
                item: this._featured,
            },
        });
    }
    get featured() {
        return this._featured;
    }
    set analytics(analytics) {
        this._analytics = analytics;
    }
    get analytics() {
        return this._analytics;
    }
    set metadata(metadata) {
        this._metadata = metadata;
    }
    get metadata() {
        return this._metadata;
    }
    set containers(v) {
        this.tag('Scroller').add(v);
    }
    set about(v) {
        var _a, _b, _c, _d, _e;
        if (!v)
            return;
        this.patch({
            ShowAbout: {
                data: {
                    title: (_a = v.title) !== null && _a !== void 0 ? _a : '',
                    content: splitString(removeHtmlParams(((_e = (_d = (_c = (_b = v.aboutOverlay) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.content) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.description) || ''), '\n'),
                },
            },
        });
    }
    set favorite({ analytics, featured }) {
        var _a;
        const { v4ID } = analytics;
        const items = [];
        this._isFavorite = UserInteractionsStoreSingleton.isFavorite(v4ID);
        this._favoriteInteraction = (_a = featured === null || featured === void 0 ? void 0 : featured.data) === null || _a === void 0 ? void 0 : _a.favoriteInteraction;
        this._favoriteItem = {
            icon: 'favorite',
            iconWidth: 48,
            label: Language.translate('favorite'),
        };
        this._removeItem = {
            icon: 'remove',
            iconWidth: 48,
            label: Language.translate('remove'),
        };
        items.push(this._isFavorite ? this._removeItem : this._favoriteItem);
        items.push({
            icon: 'more-info',
            iconWidth: 16,
            label: Language.translate('about'),
        });
        items.push({
            icon: 'shows',
            iconWidth: 38,
            label: Language.translate('shows'),
        });
        this.patch({
            SubNav: {
                items,
            },
        });
    }
    async _updateFavorite() {
        var _a, _b, _c, _d;
        if (this._isFavorite) {
            const userFavorite = UserInteractionsStoreSingleton.getUserFavorite((_a = this.analytics) === null || _a === void 0 ? void 0 : _a.v4ID);
            await UserInteractionsStoreSingleton.dispatch(removeFavorite(userFavorite));
        }
        else {
            const dataType = (_b = this.metadata) === null || _b === void 0 ? void 0 : _b.pageType;
            await UserInteractionsStoreSingleton.dispatch(addFavorite({
                titleId: (_c = this.analytics) === null || _c === void 0 ? void 0 : _c.v4ID,
                created: new Date().toDateString(),
                urlAlias: (_d = this.params) === null || _d === void 0 ? void 0 : _d.urlAlias,
            }, PARK_ENTITY_TYPES[dataType]));
            sendMetric(EVENTS.CLICK, {
                name: 'Favorite Click',
                favorite: 'add',
                brand: this.brand,
                series: this.series,
                show: this.series,
            });
            sendMetric(EVENTS.CLICK, {
                name: 'Item Favorited',
                brand: this.brand,
                'Brand Favorited': this._analytics.brand.title,
                'Favorite Type': 'add',
                genre: this._analytics.genre,
                show: this.series,
                'Show Favorited': this.series,
            });
        }
        this.updateFavoritesCache();
    }
    _resetPosition() {
        this.tag('Featured').setInactive(true);
        this.tag('Scroller').setSmooth('y', 900);
        this.tag('SubNav').setSmooth('x', 1250);
        this.tag('BackReturn').setSmooth('alpha', 0);
        this.tag('Scroller').setIndex(0);
    }
    $contentIsUnavailable() {
        this._contentIsNotAvailable = true;
        this.tag('Scroller').patch({
            x: 0,
            y: 910,
        });
        this._setState('ItemMenu');
    }
    $featuredNotAvailable() {
        this._initialState = 'ItemMenu';
    }
    _handleBack(e) {
        ContentPosition.clearPositionForCurrentPage();
        e.preventDefault();
        e.stopPropagation();
        if (Router.getHistory().length < 1) {
            Router.navigate(ROUTE.home);
        }
        else {
            const historyState = Router.getHistoryState();
            const routerHistory = Router.getHistory();
            const lastHistory = routerHistory[routerHistory.length - 1];
            if (lastHistory && historyState) {
                const newRoute = `${ROUTE.shows}/${historyState.brand}${historyState.category ? `/${historyState.category}` : ''}`;
                routerHistory[routerHistory.length - 1] = { hash: newRoute, state: historyState };
                Router.setHistory(routerHistory);
                Router.back();
            }
            else
                Router.back();
        }
    }
    static _states() {
        return [
            ItemMenuStateFactory(this),
            FeaturedStateFactory(this),
            ScrollerStateFactory(this),
            AboutStateFactory(this),
        ];
    }
}
export default WithRequestError(ShowHome);
